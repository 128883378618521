import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorTokenService  implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _authService: AuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const excludedUrls = [
      
    ];
    const isExcluded = excludedUrls.some(url => req.url.includes(url));

    let token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!isExcluded && token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!navigator.onLine) {
          // this.toastr.error('You have lost internet connection!', 'Connection Error');
        } else if (error.status === 0) {
          // this.toastr.error('No response from the server. Please try again later.', 'Server Error');
        } else if (error.status === 401) {
          // Handle 401 Unauthorized error here
          // this.user.signOut();
          this._router.navigateByUrl('sign-in');
          this._router.navigate(['sign-in']);
          localStorage.clear();
          this._authService.signOut();

        } else {
          // this.toastr.error(error.message, 'Error');
        }
        return throwError(error);
      })
    );
  }
}