import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { StorageServiceService } from 'app/core/services/storageService/storage-service.service';
import { filteredNavigation } from 'app/mock-api/common/navigation/data';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModulePermissionGuard implements CanActivate {

    constructor(
        private router: Router,
        private storageServiceService: StorageServiceService,

    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // const storedData = localStorage.getItem('userPermission');
        const localStorageData = this.storageServiceService.getData('userPermission')
        // const localStorageData = storedData ? JSON.parse(storedData) : [];

        // Get the module name from the route data
        const moduleName = route.data['moduleName'];

        // Check if the user has permission for this module
        const hasPermission = localStorageData.some(item => item['module.module_name'] === moduleName);

        if (hasPermission) {
            return true;
        } else {
            // Find the first module the user has permission for
            return this.router.createUrlTree(['/dashboard']);
            // const firstAllowedModule = filteredNavigation.find(navItem =>
            //     localStorageData.some(item => item['module.module_name'] === navItem.title)
            // );
            // if (firstAllowedModule) {
            //     // Redirect to the first allowed module
            //     return this.router.createUrlTree([firstAllowedModule.link]);
            // } else {
            //     // If no permissions are found, handle accordingly (e.g., log out or show an error)
            //     return this.router.createUrlTree(['/comment']);
            // }
        }
    }
}
