export const PrivilegeConstants = {
    CreateTournament: 'Create_Tournament',
    EditTournament: 'Edit_Tournament',
    ApproveTournament: 'Approve_Tournament',
    RejectTournament: 'Reject_Tournament',
    DeleteTournament : "Delete_Tournament",
    ViewTournament : "View_Tournament",
    DisableTournament : "Disable_Tournament",

    CreateUser: 'Create_User',
    EditUser: 'Edit_User',
    AssignUser: 'Assign_User',
    ApproveUserManagement : "Approve_User_Management",
    RejectUserManagement:"Reject_User_Management",
    DeleteUser : "Delete_User",
    ViewUser : "View_User",
    DisableUser : "Disable_User",

    CreateRole : "Create_Role",
    EditRole : "Edit_Role",
    ApproveRole : "Approve_Role",
    RejectRole : "Reject_Role",
    DeleteRole : "Delete_Role",
    ViewRole : "View_Role",
    DisableRole : "Disable_Role",

    CreateGameCategory: 'Create_Game_Category',
    EditGameCategory: 'Edit_Game_Category',
    ApproveGameCategory: 'Approve_Game_Category',
    RejectGameCategory: 'Reject_Game_Category',
    DeleteGameCategory : "Delete_Game_Category",
    ViewGameCategory : "View_Game_Category",
    DisableGameCategory : "Disable_Game_Category",

    CreateEducationVideo: 'Create_Education_Video',
    EditEducationVideo: 'Edit_Education_Video',
    ApproveEducationVideo: 'Approve_Education_Video',
    RejectEducationVideo: 'Reject_Education_Video',
    DeleteEducationVideo : "Delete_Education_Video",
    ViewEducationVideo : "View_Education_Video",
    DisableEducationVideo : "Disable_Education_Video",

    CreateJobListing: 'Create_Job_Listing',
    EditJobListing: 'Edit_Job_Listing',
    ApproveJobListing: 'Approve_Job_Listing',
    RejectJobListing: 'Reject_Job_Listing',
    DeleteJobListing : "Delete_Job_Listing",
    ViewJobListing : "View_Job_Listing",
    DisableJobListing : "Disable_Job_Listing",

    CreateLiveStreaming: 'Create_Live_Streaming',
    EditLiveStreaming: 'Edit_Live_Streaming',
    ApproveLiveStreaming: 'Approve_Live_Streaming',
    RejectLiveStreaming: 'Reject_Live_Streaming',
    DeleteLiveStreaming: "Delete_Live_Streaming",
    ViewLiveStreaming : "View_Live_Streaming",
    DisableLiveStreaming : "Disable_Live_Streaming",

    CreateSponsor: 'Create_Sponsor',
    EditSponsor: 'Edit_Sponsor',
    ApproveSponsor: 'Approve_Sponsor',
    RejectSponsor: 'Reject_Sponsor',
    DeleteSponsor : "Delete_Sponsor",
    ViewSponsor : "View_Sponsor",
    DisableSponsor : "Disable_Sponsor",

    CreateOrganizer: 'Create_Organizer',
    EditOrganizer: 'Edit_Organizer',
    ApproveOrganizer: 'Approve_Organizer',
    RejectOrganizer: 'Reject_Organizer',
    DeleteOrganizer : "Delete_Organizer",
    ViewOrganizer : "View_Organizer",
    DisableOrganizer : "Disable_Organizer",

    ApproveCommunityListing : "Approve",
    RejectCommunityListing : "Reject",
    BlockUnblockCommunityListing : "Disable",
    ArchiveCommunityListing : "Archive",
    EditCommunityListing : "Edit",
    CreateCommunityListing : "Add",
    ViewCommunityListing : "View",
    DownloadCommunityListing : "Download",

    Create : "Add",
    Edit : "Edit",
    Approve : "Approve",
    Reject : "Reject",
    Delete : "Delete",
    View : "View",
    Disable : "Disable",
    Assign : "Assign",
    Download : "Download",
    Suspend : "Suspend"
  };





  export const MODULE_NAME = {

    SPONSAR_MANAGEMENT :"Sponsor Management",
    ORGANIZER_MANAGEMENT :"Organizer Management",
    TOURNAMENT_MANAGEMENT :"Tournament Management",
    USER_MANAGEMENT : "User Management",
    JOB_LISTING : "Job Listing",
    EDUCATIONAL_VIDEO : "Educational Videos",
    GAME_CATEGORY : "Game & Category Management",
    LIVE_STREAM : "Live Stream",
    SUB_ADMIN_MANAGEMENT : "Sub-Admin Management",
    COMMUNITY_MANAGEMENT : "Community Management",
    LEADERBOARD_MANAGEMENT : "Leaderboard Management",
    REDEEM_MANAGEMENT : "Redeem Management"
};