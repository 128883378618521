<h1 class="self-center font-bold text-xl" mat-dialog-title>Change Password</h1>
<div mat-dialog-content>
  <form #sponsorForm="ngForm">
    <div class="grid grid-cols-1 gap-1">
    <mat-form-field class="full-width">
      <mat-icon
      class="text-blue-600 absolute right-4"
      [svgIcon]="
          'heroicons_solid:eye'
      "
      (click)="toggleOldPasswordVisibility()"
      ></mat-icon>
      <input matInput type="password" placeholder="Old password" 
      name="old_password" id="old_password" [(ngModel)]="old_password"/>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-icon
      class="text-blue-600 absolute right-4"
      [svgIcon]="
          'heroicons_solid:eye'
      "
      (click)="toggleNewPasswordVisibility()"
      ></mat-icon>
      <input matInput type="password" placeholder="New password" 
      name="new_password" id="new_password" [(ngModel)]="new_password"/>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-icon
      class="text-blue-600 absolute right-4"
      [svgIcon]="
          'heroicons_solid:eye'
      "
      (click)="toggleConfirmPasswordVisibility()"
      ></mat-icon>
      <input matInput type="password" placeholder="Confirm password" 
      name="confirm_password" id="confirm_password" [(ngModel)]="confirm_password"/>
    </mat-form-field>
   
</div>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button  mat-flat-button
  [color]="'primary'" (click)="onSubmit()">Add</button>
</div>